import store from '../../../store';

export default {
  path: '/cameras',
  component: () => import('./Cameras.page.vue'),
  // component: () => import('./Cameras.test.page.vue'),
  meta: {
    requiresLogin: false
  },
  beforeEnter: (to, from, next) => {
    store.dispatch('SET_MODULE', 'cameras');
    next();
  },
  // children: [
  //   {
  //     path: 'list',
  //     component: () => import('./List/Attendances.list.vue'),
  //   },
  //   {
  //     path: 'detail/:id?',
  //     component: () => import('./Detail/Attendances.detail.vue'),
  //   },
  // ],
};
