import Vue from 'vue'
import routes from './routes'
import Router from 'vue-router'
// eslint-disable-next-line no-unused-vars
import store from '../store'

Vue.use(Router)

// export const constantRoutes = [
//   {
//     path: '/',
//     component: () => import('@/views/dashboard'),
//   },
//   {
//     path: '/maps',
//     component: () => import('@/views/maps'),
//     // hidden: true
//   }
// ]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes
})


const router = createRouter()

router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach', { to, from })
  // console.log(store.getters);
  // console.log(to);
  // console.log(from);
  // console.log(next);
  // next();
  if (to.matched.some((record) => {
    return record.meta.requiresLogin;
  }) && !store.getters.isAuthenticated) {
    if(to.path !== '/cameras' && to.path !== '/cameras_dua') {
      // console.log(store.getters.isAuthenticated)
      next('/cameras_dua');
      // next();
    } else {
      next();
    }
    // window.location.href = '/'
  } else {
    next();
  }
})

// console.log(router);
// router.beforeEach((to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next('/');
//   } else {
//     next();
//   }
// });

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router