import DataRoutes from '@/views/Dashboard/Data/Data.routes'
import CamerasRoutes from '@/views/Dashboard/Cameras/Cameras.routes'
import CamerasRoutesDua from '@/views/Dashboard/Cameras_dua/Cameras_dua.routes'
import MapRoutes from '@/views/Dashboard/Map/Map.routes'

export default {
  path: '/dashboard',
  component: () => import('@/layouts/Dashboard/Dashboard.layout.vue'),
  redirect: '/dashboard/data',
  meta: {
    requiresLogin: true
  },
  children: [
    DataRoutes,
    CamerasRoutesDua,
    CamerasRoutes,
    
    MapRoutes
  ]
}