export default {
  path: '/auth',
  component: () => import('@/layouts/Auth/Auth.layout.vue'),
  redirect: '/auth/login',
  children: [
    {
      path: 'login',
      component: () => import('@/views/Auth/Login.vue'),
    }
    // {
    //   path: '/forgot',
    //   component: () => import('pages/Auth/ForgotPassword.vue'),
    // },
    // {
    //   path: '/reset-password/:hash?',
    //   component: () => import('pages/Auth/ResetPassword.vue'),
    // },
    // {
    //   path: '/verify/:hash?',
    //   component: () => import('pages/Auth/Verify.vue'),
    // },
    // {
    //   path: '/submit',
    //   component: () => import('pages/Auth/Submit.vue'),
    // },
    // {
    //   path: '/not-found',
    //   component: () => import('pages/Auth/404.vue'),
    // },
  ]
}