import axios from 'axios';
// import qs from 'qs';
// const https = require('https')

// const request = axios.create({
//   baseURL: 'https://dashboard.malangkota.go.id/app_admin/api/Wilayahmain/get_kel_kec',
//   headers: {
//     withCredentials: true,
//     auth: {
//       username: 'surya_cakep',
//       password: 'bgt'
//     },
//   }
// });

const raw_data = () => {
  return new Promise(resolve => {
    axios({
      method: 'get',
      url: 'http://api.cctv.malangkota.go.id/records/cameras',
      // auth: {
      //   username: 'surya_cakep',
      //   password: 'bgt'
      // },
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    })
      .then(res_query => {
        resolve(res_query)
        // console.log(res_query)
      })
      .catch(function (error) {
        console.log(error)
        // process.exit()
      })
  })
}

// const res = async () => {
//   let hasil = await raw_data();
//   return hasil
  
// }

// console.log(res)


// export function raw_data () {
//   return new Promise(resolve => {
//     axios({
//       method: 'get',
//       url: 'https://dashboard.malangkota.go.id/app_admin/api/Wilayahmain/get_kel_kec',
//       auth: {
//         username: 'surya_cakep',
//         password: 'bgt'
//       },
//       httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//     })
//       .then(res_query => {
//         resolve(res_query)
//         console.log(res_query.data)
//       })
//       .catch(function (error) {
//         console.log(error)
//         process.exit()
//       })
//   })
// }
export default async function AxiosNew() {
  let hasil = await raw_data();
  return hasil

  // return initPromise;
}


