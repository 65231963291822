<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

// import types from './store/types';

export default {
  name: 'App',
  head: {
    title: {
      inner: 'System Monitoring CCTV Kota Malang'
    },
    link: [
      { rel: 'icon', href: require('./assets/logo-color.png'), sizes: '16x16', type: 'image/png' },
    ]
  },
  beforeCreate() {
    // console.log('beforeCreate app');
    this.$store.dispatch('INIT');
  }
}
</script>
