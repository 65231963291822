import store from '../../../store';

export default {
  path: 'data',
  component: () => import('./Data.page.vue'),
  redirect: 'data/users',
  meta: {
    requiresLogin: true
  },
  beforeEnter: (to, from, next) => {
    store.dispatch('SET_MODULE', 'data');
    next();
  },
  children: [
    {
      path: 'users',
      component: () => import('./Users/Users.page.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('SET_SUBMODULE', 'users');
        next();
      }
    },
    {
      path: 'groups',
      component: () => import('./Groups/Groups.page.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('SET_SUBMODULE', 'groups');
        next();
      }
    },
    {
      path: 'cameras',
      component: () => import('./Cameras/Cameras.page.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('SET_SUBMODULE', 'cameras');
        next();
      }
    },
    {
      path: 'clusters',
      component: () => import('./Clusters/Clusters.page.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('SET_SUBMODULE', 'clusters');
        next();
      }
    },
  ],
};
