import Vue from 'vue'

import Axios from './axios'
import AxiosNew from './axiosNew'
import ConstData from './constant'
import Moment from './moment'
import './vuelidate'
import './vue-tel-input'

Vue.use(Axios);
Vue.use(AxiosNew);
Vue.use(ConstData);
Vue.use(Moment);

import '../store'
