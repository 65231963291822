import Vue from 'vue'
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import FormGroup from '../components/FormWrapperVuelidate.vue';

const messages = {
  required: '{attribute} is required',
  email: '{attribute} format wrong',
  phoneNumberFormat: 'Format nomor telepon salah'
};

const attributes = {
  name: 'Name',
  email: 'Email',
};

Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  messages,
  attributes,
});

Vue.component('ElFormGroup', FormGroup);
Vue.component('ElFormWrapper', templates.FormWrapper);
