import Auth from '@/layouts/Auth/Auth.routes.js'
import Dashboard from '@/layouts/Dashboard/Dashboard.routes.js'
import store from '../store'

// import axios from 'axios';
// console.log(store.getters.getCurrentAuthUser);
// console.log(store.getters.isAuthenticated);

export default [
  {
    path: '/',
    redirect: store.getters.isAuthenticated ? '/dashboard/map' : '/cameras_dua'
  },
  
  Auth,
  Dashboard,
]
