import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const state = {
  sidebar:false,
  module: null,
  submodule: null,
  detail: {
    show: false,
    id: null
  },
  user: null,
  fullscreen: false,
  collapsed: true,
  streaming: false,
  key: {
    panel: 0
  }
};
const getters = {
  getCurrentModule: state => {
    return {
      module: state.module,
      submodule: state.submodule
    }
  },
  getCurrentDetail: state => {
    return state.detail
  },
  getCurrentAuthUser: state => {
    return state.user
  },
  isAuthenticated: state => {
    return state.user ? true : false
  },
  isFullscreen: state => {
    return state.fullscreen ? true : false
  },
  isCollapsed: state => {
    return state.collapsed ? true : false
  },
  getKey: state => {
    return state.key
  }
};
const mutations = {
  TOGGLE_SIDEBAR(state, payload) {
    state.sidebar = payload
  },
  SET_MODULE (state, payload) {
    // console.log('y')
    // console.log(payload)
    state.module = payload;
  },
  SET_SUBMODULE (state, payload) {
    state.submodule = payload;
  },
  SET_DETAIL (state, payload) {
    state.detail.show = true;
    state.detail.id = payload;
  },
  async SET_USER (state, payload) {
    if(window.localStorage.getItem('ngintip')) {
      const payload = {
        data: JSON.parse(window.localStorage.getItem('ngintip'))
      }
      state.user = payload;
    } else {
      // console.log("-----------------")
      payload.data = await Vue.prototype.$request.get(`records/users/${payload.user_id}?join=roles,groups`).then(response => response.data);
      // console.log(payload)
      // console.log("-----------------")
      // payload.data = await Vue.prototype.$request.get(`records/users/${payload.user_id}?join=roles,groups`).then(response => response);
      
      state.user = payload;
    }
  },
  UNSET_USER (state) {
    state.user = null;
  },
  TOGGLE_FULLSCREEN (state, payload) {
    state.fullscreen = payload;
    // console.log(state.fullscreen);
  },
  TOGGLE_COLLAPSE (state) {
    state.collapsed = !state.collapsed;
  },
  INCREMENT_KEY (state, payload) {
    state.key[payload]++;
  }
};
const modules = {};
const actions = {
  T_SD_OPEN({ commit }) {
    // console.log('dispatch maximize');
    commit('TOGGLE_SIDEBAR', true);
  },
  T_SD_CLOSE({ commit }) {
    // console.log('dispatch maximize');
    commit('TOGGLE_SIDEBAR', false);
  },
  MAXIMIZE({ commit }) {
    // console.log('dispatch maximize');
    commit('TOGGLE_FULLSCREEN', true);
  },
  MINIMIZE({ commit }) {
    // console.log('dispatch minimize');
    commit('TOGGLE_FULLSCREEN', false);
  },
  TOGGLE_COLLAPSE({ commit }) {
    commit('TOGGLE_COLLAPSE', true);
  },
  RELOAD({ commit }, payload) {
    // console.log('RELOAD', payload);
    commit('INCREMENT_KEY', payload);
  },
  SET_MODULE ({ commit }, payload) {
    commit('SET_MODULE', payload);
  },
  SET_SUBMODULE ({ commit }, payload) {
    commit('SET_SUBMODULE', payload);
  },
  SET_DETAIL ({ commit }, payload) {
    commit('SET_DETAIL', payload);
  },
  async INIT ({ commit }) {
    if(window.localStorage.getItem('ngintip')) {
      // console.log('boleh ngintip')
      commit('SET_USER', {
        user_id: "xxx"
      });
    } else {
      await Vue.prototype.$request.get('is-auth')
      .then(response => {
        commit('SET_USER', response.data.session.user);
      })
      .catch(() => {
        commit('UNSET_USER');
      })
    }
  },
  DO_LOGIN({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$request.post('login', payload)
        .then(response => {
          commit('SET_USER', response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  },
  DO_LOGOUT({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$request.post('logout')
        .then(response => {
          commit('UNSET_USER');
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})

Vue.prototype.$store = store;
export default store;