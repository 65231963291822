import axios from 'axios';
import qs from 'qs';

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

request.interceptors.request.use((config) => {
  // console.log(config);
  if(config.method === 'get') {
    config.data = qs.stringify(config.data, { arrayFormat: 'brackets' })
  }
  return config;
})

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log('error from axios interceptors', error.response);
    if(error.response.data.code === 1011 || error.response.data.code === 1001) {
      // window.location.href = '/'
    } else {
      return Promise.reject(error)
    }
  }
);


export default {
  install (Vue) {
    Vue.prototype.$request = request;
  }
}