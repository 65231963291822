import Vue from 'vue'
// import Vuex from 'vuex'
import VueHead from 'vue-head'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'
import 'remixicon/fonts/remixicon.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-theme-dark';
import './assets/css/style.css'

// import './store'
// Vue.use(Vuex);
// const faker = require('faker');
import './utils/index';

// import './store'
// Vue.use(Plugins);
Vue.prototype.$eventHub = new Vue();

import VueDataTables from 'vue-data-tables'
Vue.use(VueDataTables)

// import VueYouTubeEmbed from 'vue-youtube-embed'
// Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" })

Vue.use(VueHead)
Vue.use(ElementUI)

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

locale.use(lang)

Vue.config.productionTip = false

// console.log('process.env', process.env);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')